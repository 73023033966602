import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <img src='https://firebasestorage.googleapis.com/v0/b/hellofarmer-app.appspot.com/o/assets%2Fbranding%2FhellofarmerLogoIcon.png?alt=media&token=d4203ffe-4c7b-4111-840f-a21c91096827' alt='hellofarmerLogoIcon' />
      </header>
      <div>
        <div>
          <img id='logoText' src='https://firebasestorage.googleapis.com/v0/b/hellofarmer-app.appspot.com/o/assets%2Fbranding%2FhellofarmerLogoText.png?alt=media&token=03914f12-e280-45d2-8b30-2f7944e52035' alt='hellofarmerLogoText' />
          <p id='motto'>
            Always fresh.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
